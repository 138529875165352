import { Routes } from '@angular/router';
import { authGuard } from './auth/auth-guard.guard';
import { verificarPermissoesGuard } from './auth/verificar-permissoes.guard';

export const routes: Routes = [
    { path: "", redirectTo: "home", pathMatch: 'full' },
    { path: "home", loadComponent: () => import("./paginas/home/home.component").then(h => h.HomeComponent), canActivate: [authGuard] },
    { path: "login", loadComponent: () => import("./paginas/login/login.component").then(l => l.LoginComponent) },
    { path: "login/:mail", loadComponent: () => import("./paginas/login/login.component").then(l => l.LoginComponent) },
    {
        path: "teste", loadComponent: () => import("./paginas/teste/teste.component").then(t => t.TesteComponent), children: [
            { path: "teste-home", loadComponent: () => import("./paginas/teste/componente-exibicao-teste/componente-exibicao-teste.component").then(a => a.ComponenteExibicaoTesteComponent) },
        ]
    },
    { path: "responder-satisfacao-cliente/:id/:token", loadComponent: () => import("./paginas/controles/satisfacao-cliente/satisfacao-cliente-responder/satisfacao-cliente-responder.component").then(r => r.SatisfacaoClienteResponderComponent) },

    //ROTAS RESPONSAVEIS POR ENGENHARIA
    {
        path: "engenharia", loadChildren: () => import("./routes/app.engenharia.routes").then(r => r.routesEngenharia), canActivate: [authGuard, verificarPermissoesGuard], data: {
            permissao: ['ROLE_ENG_VIS']
        }
    },
    //ROTAS RESPONSAVEIS POR DISPOSITIVOS
    {
        path: "dispositivo", loadChildren: () => import("./routes/app-dispositivo.routes").then(d => d.routesDispositivos), canActivate: [authGuard, verificarPermissoesGuard], data: { permissao: ['ROLE_DISP_VIS'] }
    },
    { path: "gestao-a-vista", loadComponent: () => import("./paginas/qualidade/gestao-a-vista/gestao-a-vista.component").then(g => g.GestaoAVistaComponent) },
    //ROTAS RESPONSAVEIS POR QUALIDADE
    {
        path: "qualidade", loadChildren: () => import("./routes/app.qualidade.routes").then(q => q.routesQualidade), canActivate: [authGuard, verificarPermissoesGuard], data: { permissao: ['ROLE_QUA_VIS'] }
    },
    //ROTAS RESPONSAVEIS POR CONTROLES
    {
        path: "controles", loadChildren: () => import("./routes/app.controle.routes").then(c => c.routesControle), canActivate: [authGuard, verificarPermissoesGuard], data: { permissao: ['ROLE_CONT_VIS'] }
    },
    //PRODUÇÃO
    {
        path: "producao/book", loadChildren: () => import("./routes/app.producao.routes").then(p => p.routesProducao), canActivate: [authGuard, verificarPermissoesGuard], data: { permissao: ['ROLE_CONT_VIS'] }
    },
    //ROTA ADMINISTRADOR
    {
        path: "administrador", loadChildren: () => import("./routes/app.admin.routes").then(a => a.routesAdministrador), canActivate: [authGuard, verificarPermissoesGuard], data: { permissao: ['ROLE_ADMIN'] },
    },
    //ROTA PCP
    {
        path: "pcp", loadChildren: () => import("./routes/app.pcp.routes").then(p => p.routesPcp), canActivate: [authGuard, verificarPermissoesGuard], data: { permissao: ['ROLE_PROD_VIS'] }
    },
    //ROTA INDICADORES
    {
        path: "indicadores", loadChildren: () => import("./routes/app.indicadores.routes").then(i => i.routesIndicadores), canActivate: [authGuard, verificarPermissoesGuard], data: { permissao: ['ROLE_PROD_VIS'] }
    },
    //ROTA INSPECAO
    {
        path: "inspecao", loadChildren: () => import("./routes/app.inspecao-equipamento.routes").then(i => i.routesInspecaoEquipamentos), canActivate: [authGuard, verificarPermissoesGuard], data: { permissao: ['ROLE_PROD_VIS'] }
    },
    //ROTA PUBLICA PARA RECUPERAÇÃO DE SENHA
    {
        path: "recuperar-senha/:email/:token", loadChildren: () => import("./routes/app.recuperar-senha.routes").then(s => s.routesRecuperarSenha),
    },
    //ACÕES
    {
        path: "acoes", loadChildren: () => import("./routes/app.acoes.routes").then(a => a.routesAcoes),//canActivate: [authGuard, verificarPermissoesGuard], data: { permissao: ['ROLE_PROD_VIS'] }
    },
    //Manuntenção
    {
        path: "manutencao", loadChildren: () => import("./routes/app-menutencao.routes").then(a => a.rotaManutencao),//canActivate: [authGuard, verificarPermissoesGuard], data: { permissao: ['ROLE_PROD_VIS'] }
    },
];

