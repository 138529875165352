
import { ApplicationConfig, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { PreloadAllModules, provideRouter, withPreloading, withViewTransitions } from '@angular/router';

import { routes } from './app.routes';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { authInterceptor, jwtConfig } from './security/httpInterceptorService';
import { JwtModule } from '@auth0/angular-jwt';
// import { provideQuillConfig } from 'ngx-quill/config';
import { AreaFormatterPipe } from './pipe/AreaFormatter.pipe';
import { PesoFormatterPipe } from './pipe/pesoFormatter.pipe';



registerLocaleData(localePt);

export const appConfig: ApplicationConfig = {
  providers: [
    AreaFormatterPipe,
    PesoFormatterPipe,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    provideRouter(routes,withViewTransitions(),withPreloading(PreloadAllModules)),
    // [RouterModule.forRoot(routes, { useHash: false })],
    provideHttpClient(withInterceptors([authInterceptor])),
    provideAnimationsAsync('noop'),
    provideAnimationsAsync(),
    importProvidersFrom(
      JwtModule.forRoot({
        config: jwtConfig
      }),
    ),
    // provideQuillConfig({
    //   modules: {
    //     syntax: true,
    //     toolbar: '#toolbar-container',
    //   }
    // }),

    { provide: LOCALE_ID, useValue: 'pt-br' }
  ]
};
